<template>
  <b-container>
    <b-row align-h="center" class="pt-4">
      <b-col class="col-10 col-md-7 col-lg-5 pt-5 px-0">
        <b-card style="border-radius: 0" border-variant="white" class="mb-2">
          <h3>Page not found</h3>
          <p class="text-medium mt-4 p-0">
            Please check that the link you provided is correct
          </p>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {};
</script>

<style></style>
